import { Constant } from "../../constants/Constant";
import { MakeNewtworkCall } from "../../network/BaseAxios";
import { RequestType } from "../../network/RequestType";
import { ActionType } from "./actionType";

const makeNetworkCallAddCourseMappingDrNeeraj =
  (url, emailId, body, header) => async (dispatch) => {
      try {
        const updatedUrl = `${url}?emailId=${emailId}`
      const { status, data } = await MakeNewtworkCall(
        RequestType.POST,
        updatedUrl,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.ADD_COURSE_MAPPING_DR_NEERAJ,
          payload: {
            addCourseMapping: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

export { makeNetworkCallAddCourseMappingDrNeeraj };
