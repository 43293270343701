import { ActionType } from "../action/actionType";

const initialState = {
  addCourseMapping: {
    statusCode: 0,
    responseData: "",
  },
};

export const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADD_COURSE_MAPPING_DR_NEERAJ: {
      return {
        ...state,
        addCourseMapping: {
          statusCode: action.payload.addCourseMapping.statusCode,
          responseData: [...action.payload.addCourseMapping.responseData],
        },
      };
    }
    case ActionType.RESET_ADD_COURSE_MAPPING_DR_NEERAJ: {
      return {
        ...state,
        addCourseMapping: {
          statusCode: 0,
          responseData: "",
        },
      };
    }
    default:
      return state;
  }
};
