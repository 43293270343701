import { useLocation } from "react-router-dom";
import ManageChapters from "../manage-chapters/ManageChapters";
import ManageCourses from "../manage-courses/ManageCourses";
import ManageResources from "../manage-resources/ManageResources";
import ManageSections from "../manage-section/ManageSections";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import "./ProgramManager.css"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../redux/action/actionType";

const ProgramManager = () => {
  const [value, setValue] = useState("one");
  const courseReducer = useSelector((state) => state.courseReducer);
  const dispatch = useDispatch()
  const [sectionId, setSectionId] = useState("")

  const handleChange = (event, newValue) => {
    setSectionId("")
    setValue(newValue);
  };

  useEffect(() => {
    if (courseReducer.createChapterBySectionId.statusCode === 200) { 
      setSectionId(courseReducer.createChapterBySectionId.sectionId);
      setValue("three")
      dispatch({
        type: ActionType.RESET_CREATE_CHAPTER_BY_SECTION_ID,
        payload: {}
      })
    }
  }, [courseReducer.createChapterBySectionId.statusCode]);

  const { state } = useLocation();

  return (
    <TabContext
      value={value}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
    >
      <TabList onChange={handleChange} variant="fullWidth" className="tab-list">
        <Tab
          value="one"
          label={<span className="font-change-style">Manage Course</span>}
          className="tab-style"
        />
        <Tab
          value="two"
          label={<span className="font-change-style">Manage Section</span>}
          className="tab-style"
        />
        <Tab
          value="three"
          label={<span className="font-change-style">Manage Chapter</span>}
          className="tab-style"
        />
        <Tab
          value="four"
          label={<span className="font-change-style">Manage Resource</span>}
          className="tab-style"
        />
      </TabList>
      <TabPanel value="one">
        <ManageCourses courseId={state} />
      </TabPanel>
      <TabPanel value="two">
        <ManageSections courseId={state} />
      </TabPanel>
      <TabPanel value="three">
        <ManageChapters sectionId={sectionId} courseId={state} />
      </TabPanel>
      <TabPanel value="four">
        <ManageResources />
      </TabPanel>
    </TabContext>
  );
};

export default ProgramManager;
