export const ActionType = {
  LOGIN: "LOGIN",
  RESET_LOGIN: "RESET_LOGIN",
  INITIAL_SIDE_NAVIGATION_VALUE: "INITIAL_SIDE_NAVIGATION_VALUE",
  UPDATED_SIDE_NAVIGATION_VALUE: "UPDATED_SIDE_NAVIGATION_VALUE",
  SHOW_LOADER: "SHOW_LOADER",
  ERROR_VISIBLE: "ERROR_VISIBLE",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  GET_ALL_COURSE: "GET_ALL_COURSE",
  RESET_ALL_COURSE: "RESET_ALL_COURSE",
  CREATE_COURSE: "CREATE-COURSE",
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  UPDATE_PAGE_COUNT: "UPDATE_PAGE_COUNT",
  COURSE_CREATE_RESPONSE: "COURSE_CREATE_RESPONSE",
  RESET_COURSE_CREATE_RESPONSE: "RESET_COURSE_CREATE_RESPONSE",
  UPDATE_COURSE_RESPONSE: "UPDATE_COURSE_RESPONSE",
  RESET_UPDATE_COURSE_RESPONSE: "RESET_UPDATE_COURSE_RESPONSE",
  SECTION_CREATE_RESPONSE: "SECTION_CREATE_RESPONSE",
  GET_COURSE_BY_ID: "GET_COURSE_BY_ID",
  RESET_GET_COURSE_BY_ID: "RESET_GET_COURSE_BY_ID",
  DELETE_COURSE: "DELETE_COURSE",
  RESET_DELETE_COURSE_RESPONSE: "RESET_DELETE_COURSE_RESPONSE",
  GET_SECTION_BY_COURSE_ID: "GET_SECTION_BY_COURSE_ID",
  RESET_SECTION_BY_COURSE_ID: "RESET_SECTION_BY_COURSE_ID",
  RESET_CREATE_SECTION_RESPONSE: "RESET_CREATE_SECTION_RESPONSE",
  CREATE_CHAPTER_BY_SECTION_ID: "CREATE_CHAPTER_BY_SECTION_ID",
  RESET_CREATE_CHAPTER_BY_SECTION_ID: "RESET_CREATE_CHAPTER_BY_SECTION_ID",
  GET_ALL_CHAPTER_BY_SECTION_ID: "GET_ALL_CHAPTER_BY_SECTION_ID",
  RESET_GET_ALL_CHAPTER_BY_SECTION_ID: "RESET_GET_ALL_CHAPTER_BY_SECTION_ID",
  CREATE_QUESTION_REQUEST: "CREATE_QUESTION_REQUEST",
  RESET_CREATE_QUESTION_REQUEST: "RESET_CREATE_QUESTION_REQUEST",
  CHAPTER_QUESTION_MAPPING: "CHAPTER_QUESTION_MAPPING",
  RESET_CHAPTER_QUESTION_MAPPING: "RESET_CHAPTER_QUESTION_MAPPING",
  UPLOAD_FILES_BY_CHAPTER_ID: "UPLOAD_FILES_BY_CHAPTER_ID",
  RESET_UPLOAD_FILES_BY_CHAPTER_ID: "RESET_UPLOAD_FILES_BY_CHAPTER_ID",
  GET_CHAPTER_BY_ID: "GET_CHAPTER_BY_ID",
  RESET_GET_CHAPTER_BY_ID: "RESET_GET_CHAPTER_BY_ID",
  DELETE_TYPE_BY_ID: "DELETE_TYPE_BY_ID",
  RESET_DELETE_TYPE_BY_ID: "RESET_DELETE_TYPE_BY_ID",
  SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE: "SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE",
  RESET_SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE:
    "RESET_SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE",
  CREATE_CAROUSEL: "CREATE_CAROUSEL",
  RESET_CREATE_CAROUSEL: "RESET_CREATE_CAROUSEL",
  GET_ALL_CAROUSEL: "GET_ALL_CAROUSEL",
  RESET_GET_ALL_CAROUSEL: "RESET_GET_ALL_CAROUSEL",
  DELETE_CAROUSEL: "DELETE_CAROUSEL",
  RESET_DELETE_CAROUSEL: "RESET_DELETE_CAROUSEL",
  DELETE_SECTION: "DELETE_SECTION",
  RESET_DELETE_SECTION: "RESET_DELETE_SECTION",
  SECTION_DELETE_MAPPING: "SECTION_DELETE_MAPPING",
  RESET_SECTION_DELETE_MAPPING: "RESET_SECTION_DELETE_MAPPING",
  MAP_DURATION_WITH_VIDEOS: "MAP_DURATION_WITH_VIDEOS",
  RESET_MAP_DURATION_WITH_VIDEOS: "RESET_MAP_DURATION_WITH_VIDEOS",
  DELETE_QUESTION_BY_ID: "DELETE_QUESTION_BY_ID",
  RESET_DELETE_QUESTION_BY_ID_FROM_HERE:
    "RESET_DELETE_QUESTION_BY_ID_FROM_HERE",
  DELETE_QUESTION_BY_ID_FROM_HERE: "DELETE_QUESTION_BY_ID_FROM_HERE",
  RESET_DELETE_QUESTION_BY_ID: "RESET_DELETE_QUESTION_BY_ID",
  UPDATE_SECTION: "UPDATE_SECTION",
  RESET_UPDATE_SECTION: "RESET_UPDATE_SECTION",
  DELETE_CHAPTER_BY_ID: "DELETE_CHAPTER_BY_ID",
  RESET_DELETE_CHAPTER_BY_ID: "RESET_DELETE_CHAPTER_BY_ID",
  PUBLISH_COURSE: "PUBLISH_COURSE",
  RESET_PUBLISH_COURSE: "RESET_PUBLISH_COURSE",
  QUESTION_IMAGE: "QUESTION_IMAGE",
  RESET_QUESTION_IMAGE: "RESET_QUESTION_IMAGE",
  EDIT_QUESTION_IMAGE: "EDIT_QUESTION_IMAGE",
  RESET_EDIT_QUESTION_IMAGE: "RESET_EDIT_QUESTION_IMAGE",
  EDIT_QUESTION: "EDIT_QUESTION",
  RESET_EDIT_QUESTION: "RESET_EDIT_QUESTION",
  GET_ALL_COURSE_RESPONSE: "GET_ALL_COURSE_RESPONSE",
  RESET_GET_ALL_COURSE_RESPONSE: "RESET_GET_ALL_COURSE_RESPONSE",
  GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES:
    "GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES",
  RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES:
    "RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES",
  GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES:
    "GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES",
  RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES:
    "RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES",
  ADD_FILE: "ADD_FILE",
  RESET_ADD_FILE: "RESET_ADD_FILE",
  GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE:
    "GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE",
  RESET_GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE:
    "RESET_GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE",
  GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS:
    "GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS",
  RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS:
    "RESET_GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS",
  GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS:
    "GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS",
  RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS:
    "RESET_GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS",
  ADD_QUESTIONS: "ADD_QUESTIONS",
  RESET_ADD_QUESTIONS: "RESET_ADD_QUESTIONS",
  DELETE_QUESTION_IMAGE_BY_ID: "DELETE_QUESTION_IMAGE_BY_ID",
  RESET_DELETE_QUESTION_IMAGE_BY_ID: "RESET_DELETE_QUESTION_IMAGE_BY_ID",
  SEND_NOTIFICATION_TO_USER_ON_CART_BASIS:
    "SEND_NOTIFICATION_TO_USER_ON_CART_BASIS",
  RESET_SEND_NOTIFICATION_TO_USER_ON_CART_BASIS:
    "RESET_SEND_NOTIFICATION_TO_USER_ON_CART_BASIS",
  GET_ALL_PUBLISHED_COURSES: "GET_ALL_PUBLISHED_COURSES",
  RESET_GET_ALL_PUBLISHED_COURSES: "RESET_GET_ALL_PUBLISHED_COURSES",
  COPY_CHAPTER: "COPY_CHAPTER",
  RESET_COPY_CHAPTER: "RESET_COPY_CHAPTER",
  COPY_SECTION: "COPY_SECTION",
  RESET_COPY_SECTION: "RESET_COPY_SECTION",
  GET_ALL_PUBLISHED_COURSE: "GET_ALL_PUBLISHED_COURSE",
  RESET_GET_ALL_PUBLISHED_COURSE: "RESET_GET_ALL_PUBLISHED_COURSE",
  SEND_EMAIL_TO_ALL_USER: "SEND_EMAIL_TO_ALL_USER",
  RESET_SEND_EMAIL_TO_ALL_USER: "RESET_SEND_EMAIL_TO_ALL_USER",
  COPY_COURSE: "COPY_COURSE",
  RESET_COPY_COURSE: "RESET_COPY_COURSE",
  DELETE_COURSE_DURATION_MAPPING: "DELETE_COURSE_DURATION_MAPPING",
  RESET_DELETE_COURSE_DURATION_MAPPING: "RESET_DELETE_COURSE_DURATION_MAPPING",
  GET_QUESTIONS_BY_CHAPTER_ID: "GET_QUESTIONS_BY_CHAPTER_ID",
  RESET_GET_QUESTIONS_BY_CHAPTER_ID: "RESET_GET_QUESTIONS_BY_CHAPTER_ID",

  //! expo action type
  SEND_NOTIFICATION_THROUGH_EXPO_SERVER:
    "SEND_NOTIFICATION_THROUGH_EXPO_SERVER",
  RESET_SEND_NOTIFICATION_THROUGH_EXPO_SERVER:
    "RESET_SEND_NOTIFICATION_THROUGH_EXPO_SERVER",

  //! get users data
  GET_ALL_USER: "GET_ALL_USER",
  RESET_GET_ALL_USER: "RESET_GET_ALL_USER",

  //! get payments data
  GET_ALL_PAYMENTS: "GET_ALL_PAYMENTS",
  RESET_GET_ALL_PAYMENTS: "RESET_GET_ALL_PAYMENTS",

  //! enroll student into course
  ADD_COURSE_MAPPING_DR_NEERAJ: "ADD_COURSE_MAPPING_DR_NEERAJ",
  RESET_ADD_COURSE_MAPPING_DR_NEERAJ: "RESET_ADD_COURSE_MAPPING_DR_NEERAJ",

  //! get all email address
  GET_ALL_EMAIL: "GET_ALL_EMAIL",
  RESET_GET_ALL_EMAIL: "RESET_GET_ALL_EMAIL",

  //! send email to all user
  SEND_EMAIL_TO_USER: "SEND_EMAIL_TO_USER",
  RESET_SEND_EMAIL_TO_USER: "RESET_SEND_EMAIL_TO_USER",

  //! save notification 
  SAVE_NOTIFICATION: "SAVE_NOTIFICATION",
  RESET_SAVE_NOTIFICATION: "RESET_SAVE_NOTIFICATION",

  //! get all question
  GET_ALL_QUESTION: "GET_ALL_QUESTION",
  RESET_GET_ALL_QUESTION: "RESET_GET_ALL_QUESTION",

  // map order with question
  MAP_ORDER_WITH_QUESTION: "MAP_ORDER_WITH_QUESTION",
  RESET_MAP_ORDER_WITH_QUESTION: "RESET_MAP_ORDER_WITH_QUESTION",

  //! get question by id
  GET_QUESTION_BY_ID: "GET_QUESTION_BY_ID",
  RESET_GET_QUESTION_BY_ID: "RESET_GET_QUESTION_BY_ID",

  // get result by chapter id
  GET_RESULT_BY_CHAPTER_ID: "GET_RESULT_BY_CHAPTER_ID",
  RESET_GET_RESULT_BY_CHAPTER_ID: "RESET_GET_RESULT_BY_CHAPTER_ID"
};
