const NetworkConstant = {
  //! Base URL for notification
  NOTIFICATION_URL:
    "https://services.reproneetlearning.com/service/notification/notify",

  //! URL for sending the email to users
  EMAIL_URL: "https://services.reproneetlearning.com/service/email/inviteAll",
  notification: "v1/api/courses/notification",

  //! End points for api url
  signIn: "/v1/api/user/signin",
  getAllCourse: "/v1/api/courses/getallcourses",
  createCourse: "/v1/api/courses/create",
  createSection: "/v1/api/section/create",
  getCourseByCourseId: "/v1/api/courses/getcoursesbyid",
  deleteCourseByCourseId: "v1/api/courses/deletecourse",
  getSectionByCourseId: "v1/api/courses/getsection-by-courseid?courseId=",
  createChapterBySectionId: "v1/api/chapter/createchapter",
  getAllChapterBySectionId: "v1/api/chapter/getallchapterbysectionId",
  chapterQuestionMapping: "v1/api/chapter/chapter-question-mapping",
  uploadFilesByChapterId: "v1/api/chapter/uploadfilesbychapterId",
  getChapterById: "v1/api/chapter/getchapterbyid?chapterId=",
  deleteTypeById: "v1/api/chapter/delete-type-by-id?id=",
  sendNotificationToAllDeviceType:
    "v1/api/user/send-notification-to-all-devicetype",
  sendNotificationToUserOnCartBasis:
    "v1/api/user/send-notification-to-user-oncartbasis",
  createCarousel: "v1/api/carousel/create",
  getAllCarousel: "v1/api/carousel/getallcarousels?size=50&page=0",
  deleteCarousel: "v1/api/carousel/deletecarousel/",
  deleteSection: "v1/api/section/deletecourse/",
  mapDurationWithVideo: "v1/api/chapter/map-duration-with-video",
  deleteQuestionById: "v1/api/chapter/deleteQuestionByid",
  updateSection: "v1/api/section/update",
  deleteChapterById: "v1/api/chapter/deletechapterByid",
  sectionDeleteMapping: "v1/api/section/delete-mapping?",
  updateCourse: "v1/api/courses/update-course",
  publishCourse: "v1/api/courses/publish-course",
  questionImage: "v1/api/chapter/question-image",
  editQuestion: "v1/api/chapter/edit-question",
  addFile: "v1/api/chapter/add-file",
  addQuestions: "v1/api/chapter/add-questions",
  deleteQuestionImageById: "v1/api/chapter/delete-question-images-by-id",
  getAllUser: "v1/api/user/get-all-user",
  getAllPublishedCourses: "v1/api/courses/getallpublisedcourses",
  addChapters: "v1/api/section/addchapters",
  addSections: "v1/api/courses/addsections",
  getAllPublishedCourses: "v1/api/courses/getallpublisedcourses",
  sendEmailToAllUser: "v1/api/courses/notification",
  deleteCourseSectionMapping: "v1/api/courses/delete-course-section-mapping",
  deleteChapterSectionMapping: "v1/api/chapter/deletechaptersectionmapping",
  copyCourseSection: "v1/api/courses/copy-course-section",
  deleteCourseDurationMapping: "v1/api/courses/deletecourse-duration-mapping",
  getQuestionsByChapterId: "v1/api/chapter/get-questions-by-chapter-id",
  getAllPayments: "v1/api/user/get-all-payments",
  deleteChapterQuestionMapping:
    "v1/api/chapter/delete-chapter-question-mapping",
  addCourseMappingDrNeeraj: "v1/api/user/add-course-mapping-dr-neeraj",
  getEnrolledUserEmail: "v1/api/courses/emails",
  saveNotification: "v1/api/user/save-notification",
  getAllQuestions: "v1/api/question/getallquestions",
  mapOrderWithQuestion: "v1/api/chapter/map-order-with-question",
  getQuestionByQuestionId: "v1/api/chapter/get-questions-by-question-id",
  getResultsByChapterId: "v1/api/chapter/get-results-by-chapter-id",
};

export { NetworkConstant };
